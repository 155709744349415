

/* Custom CSS */
.Header {
  width: 100%; height: 40px;
}
.SideBar{
  width: 200px;
  border: none !important;
}
.MainArea{
  width: calc(100vw - 200px);
  box-sizing: border-box;
}
.logo{
  height: 100px;
}
.border-l{
  border-left: 1px solid #666;
}
.inputClass{
  display: block;
  width: 100% !important;
  padding: .375rem .75rem;
  font-size: 1rem !important;
  height: auto !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff;
  background-clip: padding-box ;
  border: 1px solid #ced4da !important;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.Error{
  color: red;
}

.color-red{
  color: red;
}

.formheadingCont{
  border-bottom: 1px solid #e7e7e7;
  padding: 5px 15px 5px 15px;
  margin: 10px 0px 10px 0px;
}

.formheading{
  font-size: 20px;
}

.tablelink{
  color: #495057;
  text-decoration: none;
  cursor: pointer;
}

.removeBtn{
  color: red;
  font-size: 12px;
  cursor: pointer;
  float: right;
}

.ActionIcon{
  cursor: pointer;
  color: #007bff;
}
.table>:not(:first-child) {
  border-top: inherit;
}

.ActionIconsDefault{
  cursor: pointer;

}

.custdate{
  border: none !important;
  outline: none;
  height: 38px;
  padding: 0px;
}

.bg-grey{
  background-color: #e7e7e7;
}

.custTable{
  font-size: 14px;
}

.CustTextBox-50{
  width: 50px;
  text-align: end;
}
.CustTextBox-60{
  width: 60px;
  text-align: end;
}
.CustTextBox-70{
  width: 70px;
  text-align: end;
}
.CustTextBox-80{
  width: 80px;
  text-align: end;
}
.CustTextBox-100{
  width: 100px;
  text-align: end;
}

.addmorerow{
  height: 10px;
}

.ErrorTd{
  border-color: red !important;
 color: red !important;
}

.MySignupDiv{
  width: 100%;
  height: 100%;
}

.MainHeading{
  font-size: 42px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.MainSubHeading{
  font-size: 36px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.Yellow {
  color: #f8f83e !important;
}
.loginouter{
  height: calc(100vh - 150px);
}

.LogoText{
  font-weight: 600;
  font-size: 32px;
  color: #2c2c2c;
}

.ListMainHeading{
  font-weight: 500;
  font-size: 22px;
  color: #333333;
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MenuIcon{
  background: transparent !important;
  border: none !important;
  color: #222 !important;
  padding: 0px !important;
}

.MenuIcon:focus{
  box-shadow: none !important;
}

.filterContainer{
  background-color: #fdfcfc;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.FiltersHeading{
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

.FilterTag{
  font-size: 12px;
  padding: 5px 10px;
  background-color: #c2c2c2;
  margin-right: 5px;
}

.POLeftOuter{
  background-color: #fdfcfc;
}
.w-50{
  width: 50%;
}
.SuppDetailCont{
  height: 60px;
  background-color: #6366f1;
  margin: 5px;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PORightHeading{
  border-bottom: 1px solid #e7e7e7;
}

.SupplierAddressCont{
  height: 100px;
  background-color: #e7e7e7;
  border-radius: 10px;
  font-size: 14px;
  padding: 10px 10px;
}

.firstColoumn{
  min-width: 200px !important;
}

/* th:first-child, td:first-child
{
  position:sticky;
  left:0px;
  background-color:#f8f9fa;
} */

.BillingOuter{
  border: 1px solid #e7e7e7;
  padding: 20px;
  min-height: 150px;
}

.p-inputtext {
  width: 100%;
  padding: 0.40rem 0.75rem !important;
}

.tableFixHead          { overflow: auto; height: calc(100vh - 180px); overflow-y: scroll; }
.tableFixHead thead { position: sticky; top: 0; z-index: 1;  margin-top: -2px;}

.tableFixHead tr {
  box-shadow: inset .5px .5px #e7e7e7, 0 .5px #e7e7e7;
}

.MyStepperPanelContent{
  overflow: auto
}

.p-menu ul {
  margin: 0;
  padding: 0 !important;
  list-style: none !important;
}

.p-menu .p-menuitem-link {
  text-decoration: none !important;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e5e7eb !important;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e5e7eb !important;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #374151;
  background: #f9fafb;
  transition: box-shadow 0.2s;
}

.p-tabview-nav-link {
  -webkit-user-select: none;
  text-decoration: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #e5e7eb;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #e5e7eb transparent;
  background: #ffffff;
  color: #6b7280;
  transition: box-shadow 0.2s;

}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #6366f1;
  color: #6366f1;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-autocomplete-items {
  padding: 0;
  list-style-type: none;
}
.p-tabview-nav {
  padding: 0 !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #e5e7eb;
  color: #6b7280;
  background: #f9fafb;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-accordion-header-link {
  text-decoration: none;
}

.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-link {
  color: #6b7280;
}
.p-panelmenu .p-panelmenu-header-link {

  text-decoration: none;
}
.p-panelmenu .p-menuitem-link {
  user-select: none;
  text-decoration: none;
}
.navsidebar{
  background-color: #f8f8f8;
  border-right: 1px solid #e7e7e7;
  height: calc(100vh - 60px);
  width: 250px !important;
  overflow: auto;
  float: left;
}
.WorkingArea{
  float: left;
  width: calc(100vw -200px) !important;
}
.p-panelmenu .p-panelmenu-content {
  padding: 0.5rem 0;
  border: none;
  background: #ffffff;
  color: #4b5563;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
  background: none;
  border-color: none;
  color: #374151;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
  border: none;
  color: #6b7280;
  background: transparent;
  border-radius: 0px;
  transition: box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
  color: #4b5563;
  background: none;
}

.w-200{
  width: 200px;
}

.UserName{
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #e7e7e7;
}

.hero {
  height: '100vh';
  background: linear-gradient(0deg,rgba(255, 0, 150, 0.3), rgba(255, 0, 150, 0.3)), url(https://spherewms.com/wp-content/uploads/2024/01/SPH-Whse-Inv-Mgmt-Blog-shutterstock_1930996376-1-1536x768.webp);
  color: 'white';
  text-align: 'center';
  display: 'flex';
  justify-content: 'center';
  flex-direction: 'column';
  align-items: 'center';
}

.Sidebar{
  float: left;
  background-color: #f8f8f8;
  height: calc(100vh - 58px);
  border-right: 1px solid #e7e7e7;
}
.ReportArea{
  float: left;
  width: calc(100vw - 220px);
  height: calc(100vh - 58px);
  overflow: scroll;
  padding: 20px;
  box-sizing: border-box;
}

.nav-link {
  display: block;
  padding: 0.2rem 1rem;
  color: #222;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.NavigationHeading{
  font-size: 16px;
  font-weight: 600;
  padding: 5px;
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
  margin-top: -1px;
  z-index: 999;
  
}
.Level1Nav {
list-style-type: none;
padding-left: 0px;
  
}

.HeaderTd{
  width: 25%;
  background-color: #f0f1ff !important;
  text-align: right;
  font-weight: 600;
}
.ValueTD {
  width: 25%;
}
.HomeBanner{
  position: relative;
  padding-top: 30px;
  padding-bottom: 100px;


}

.HomeBanner::before{
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-image: url(/src/images/bannerbg1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Banner{
  height: calc(100vh - 70px);
  min-height: 500px;
  max-height: 700px;
  position: relative;

}

.Banner::before{
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-image: url(/src/images/bannerbg1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.BannerMainText1{
  position: relative;
  font-size: 28px;
  width: 100%;
  font-weight: 400;
  background-color: #0d6efd;
  padding: 10px 30px 10px 30px;
  border-radius: 10px 10px 0px 0px;
  color: #fff;
}
.BannerMainText2{
  position: relative;
  position: relative;
  font-size: 48px;
  font-weight: 700;
}

.Banner-SubText{
  position: relative;
  font-size: 18px;
  font-weight: 400;
}

.cust-big-btn{
  position: relative;
  padding: 10px 30px;
}

#howItWorks{
  position: relative;
}

.HowitWorksText{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #0d6efd;
  z-index: 1;
}

#features{
  background-color: #f0f1ff;
}

.custOuterNavbar{
  font-size: 18px;
  background-color: #fff;
}

.LoginUl{
  list-style: none;
  padding-left: 0;
  font-size: 20px;
  font-weight: 600;
}

@media screen and (min-width: 576px) {

}

@media screen and (min-width: 768px) {
  .MySignupDiv{
    padding-right: 30px;
  }
  .HowitWorksImage{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-image: url(./images/teamwork.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .HowitWorksText{
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: #0d6efd;
    z-index: 1;
  }
}

@media screen and (min-width: 992px) {
  
  .BannerMainText1{
    position: relative;
    font-size: 32px;
    font-weight: 400;
    background-color: #0d6efd;
    padding: 10px 30px 10px 30px;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
  }
  .BannerMainText2{
    position: relative;
    position: relative;
    font-size: 58px;
    font-weight: 700;
  }
  
  .Banner-SubText{
    position: relative;
    font-size: 20px;
    font-weight: 400;
  }
  .HomeBanner{
    height: 100vh;
    min-height: 500px;
    max-height: 700px;
    position: relative; 
  
  }
  
  .HomeBanner::before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: url(/src/images/bannerbg1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media screen and (min-width: 1200px) {

}